import React from 'react'
import '../styles/HeroSection.css'
const HeroSection = () => {



  return (
    <div className="container-fluid Hero">

      <div className="col-12 col-lg-12 collections">
        <h1>Unique Collection</h1>
        <h1 className='Brand'>NEW BRAND DESIGN</h1>
      </div>

    </div>

  )
}

export default HeroSection